import Vue from 'vue';
import VueNotifications from 'vue-notifications';

export default {
  // getUserAccounts(ctx, props = { ids: [] }) {
  //   ctx.$store.commit('toggleLoadingPortalUserAccounts');
  //   const config = props.query ?
  //     {
  //       params: {
  //         profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
  //         limit: ctx.filters.limit,
  //         offset: ctx.filters.offset,
  //         sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
  //         q: ctx.formattedQ,
  //         ids: props.ids,
  //         location_id: ctx.filters.location?.data?.id || [],
  //         with_childs: ctx.filters.location?.with_childs || false
  //       }
  //     } :
  //     { params: { ids: props.ids } };
  //   if (props.query && ctx.filters.search !== '') {
  //     config.params.search = ctx.filters.search;
  //   }
  //   Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/account`, config).then(
  //     (response) => {
  //       if (response.status === 200 && response.data.status === 'success') {
  //         // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
  //         ctx.$store.commit('pushPortalUserAccountsList', response.data.data);
  //         ctx.totalAccountsResult = response.data.total;
  //         if (ctx.$store.state.portalUserAccountsList.length >= response.data.total) {
  //           ctx.canLoadMoreAccounts = false;
  //         } else {
  //           ctx.canLoadMoreAccounts = true;
  //         }
  //       } else {
  //         VueNotifications.error({ message: response.data.description });
  //       }
  //       ctx.$store.commit('toggleLoadingPortalUserAccounts');
  //     },
  //     (err) => {
  //       // VueNotifications.error({ message: err });
  //
  //       // show errors in the console instead of the alert window
  //       if (
  //         Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
  //         typeof window.isShowAxiosErrorInConsole === 'function' &&
  //         window.isShowAxiosErrorInConsole(err)
  //       ) {
  //         window.showAxiosErrInConsole(err);
  //       } else {
  //         VueNotifications.error({ message: err });
  //       }
  //
  //       ctx.error = err;
  //       ctx.$store.commit('toggleLoadingPortalUserAccounts');
  //     }
  //   );
  // },
  getUserAccounts(ctx, props = { ids: [] }) {
    ctx.$store.commit('toggleLoadingPortalUserAccounts');
    const config = props.query ?
      {
        params: {
          profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          q: ctx.formattedQ,
          ids: props.ids,
          location_id: ctx.filters.location?.data?.id || [],
          with_childs: ctx.filters.location?.with_childs || false,
          location: ctx.filters.location?.data?.id || [],
        }
      } :
      { params: { ids: props.ids } };
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/account`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
          ctx.$store.commit('pushPortalUserAccountsList', response.data.data);
          ctx.totalAccountsResult = response.data.total;
          if (ctx.$store.state.portalUserAccountsList.length >= response.data.total) {
            ctx.canLoadMoreAccounts = false;
          } else {
            ctx.canLoadMoreAccounts = true;
          }
        } else {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      }
    );
  },
  getUserAccountsForUseInHotelVouchers(ctx, props = {ids: []}) {
    ctx.$store.commit('toggleLoadingPortalUserAccounts');
    const config = props.query ?
      {
        params: {
          profiles: ctx.filters.profiles.length ? ctx.filters.profiles.map((profile) => profile.id) : [],
          limit: ctx.filters.limit,
          offset: ctx.filters.offset,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`,
          q: ctx.formattedQ,
          ids: props.ids,
          location_id: ctx.filters.location?.data?.id || [],
          with_childs: ctx.filters.location?.with_childs || false,
          location: ctx.filters.location?.data?.id || [],
        }
      } :
      {
        params: {
          ids: props.ids,
          location_id: ctx.$store.getters.getDefaultLocationForRequests(ctx),
          with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
          location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        }
      };
    if (props.query && ctx.filters.search !== '') {
      config.params.search = ctx.filters.search;
    }
    Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/account`, config).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalUserAccountsList', response.data.data);
          ctx.$store.commit('pushPortalUserAccountsList', response.data.data);
          ctx.totalAccountsResult = response.data.total;
          if (ctx.$store.state.portalUserAccountsList.length >= response.data.total) {
            ctx.canLoadMoreAccounts = false;
          } else {
            ctx.canLoadMoreAccounts = true;
          }
        } else {
          VueNotifications.error({message: response.data.description});
        }
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({message: err});
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      }
    );
  },

  createUserAccount(ctx, account) {
    ctx.$store.commit('toggleMutePortalUserAccountEdit');
    Vue.axios
      .post(`${RESTAPI || ''}/api/portal/admin/account`, {
        request: [account]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('userAccount.notificationUserAccountCreatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        }
      );
  },

  /* CORS error */
  updateUserAccount(ctx, account) {
    ctx.$store.commit('toggleMutePortalUserAccountEdit');
    Vue.axios
      .put(`${RESTAPI || ''}/api/portal/admin/account`, {
        request: [account]
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('userAccount.notificationUserAccountUpdatedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        }
      );
  },

  /* CORS error */
  deleteUserAccount(ctx, account) {
    /* ctx.deletingPageModal = false; */
    ctx.$store.commit('toggleMutePortalUserAccountEdit');
    Vue.axios
      .delete(`${RESTAPI || ''}/api/portal/admin/account`, {
        params: {
          ids: [account.id]
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            VueNotifications.success({ message: ctx.$t('userAccount.notificationUserAccountDeletedSuccessfully') });
            ctx.getWithQuery(ctx);
            ctx.cancelEditMode();
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleMutePortalUserAccountEdit');
        }
      );
  },

  getUserTransactionHistory(ctx, account) {
    // ctx.$store.commit('toggleLoadingPortalUserAccounts');

    Vue.axios
      .get(`${RESTAPI || ''}/api/portal/admin/transaction`, {
        params: {
          ids: [account.id],
          limit: ctx.filters.limit,
          sort: `${ctx.filters.sort_order === -1 ? '-' : ''}${ctx.filters.sort_by}`
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            ctx.$store.commit('setPortalUserTransactionsList', response.data.data);
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          // ctx.$store.commit('toggleLoadingPortalUserAccounts');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.$store.commit('toggleLoadingPortalUserAccounts');
        }
      );
  },
  /** Добавляет пользователя в blacklist
   *
   * @param {*} ctx - Vue
   * @param {'del_bl'|'add_bl'|'del_al'|'add_al'} action - list
   * @param {{profileId: String, accountId: string}} params - ID Профиля и ID Пользователя
   */
  actionWithList(ctx, action, params = {}) {
    ctx.$store.commit('toggleLoadingPortalUserAccounts');

    Vue.axios({
      method: 'put',
      url: `${RESTAPI || ''}/api/portal/admin/profile`,
      data: {
        account_to_list: params.accountId,
        action_list: action,
        request: [{ id: params.profileId }]
      }
    }).then(
      (response) => {
        if (response.status === 200 && response.data.status === 'success') {
          // ctx.$store.commit('setPortalUserTransactionsList', response.data.data);
          if (action === 'add_bl' || action === 'del_bl') {
            const thisProfile = ctx.portalProfilesList.find((profile) => profile.id === params.profileId);
            thisProfile.black_list = response.data.data;
          }
          if (action === 'add_al' || action === 'del_al') {
            const thisProfile = ctx.portalProfilesList.find((profile) => profile.id === params.profileId);
            thisProfile.access_list = response.data.data;
          }
          if (action === 'add_wl' || action === 'del_wl') {
            const thisProfile = ctx.portalProfilesList.find((profile) => profile.id === params.profileId);
            thisProfile.white_list = response.data.data;
          }
          // VueNotifications.success({ message: response.data.status.toUpperCase() });
          VueNotifications.success({ message: ctx.$t('userAccount.notificationUserAccountSuccess') });
        } else {
          VueNotifications.error({ message: response.data.status.toUpperCase() });
        }
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingPortalUserAccounts');
      }
    );
  },
  getUserAccountById(ctx, account) {
    // ctx.$store.commit('toggleLoadingPortalUserAccounts');

    Vue.axios
      .get(`${RESTAPI || ''}/api/portal/admin/account`, {
        params: {
          q: { _id: account.id },
          with_childs: true
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            [ctx.account] = response.data.data;
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          // ctx.$store.commit('toggleLoadingPortalUserAccounts');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.$store.commit('toggleLoadingPortalUserAccounts');
        }
      );
  },
  getUserAccountByIdFromVouchers(ctx, accountId) {
    // используется в разделе с Ваучерами, при открытии модалки - подтягиваются данные  об аккаунте по его id
    ctx.$store.commit('getAccountByIdFromVoucherRequestBegin');
    Vue.axios
      .get(`${RESTAPI || ''}/api/portal/admin/account`, {
        params: {
          // ids: [accountId]
          q: { _id: accountId },
          with_childs: true
        }
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data.status === 'success') {
            // [ctx.account] = response.data.data;
            // console.log(response.data.data)
            if (Array.isArray(response.data.data) && response.data.data.length > 0) {
              ctx.accountSelectedInSelectorInVoucherModal = JSON.parse(JSON.stringify(response.data.data[0]));
              ctx.redrawAccountSelectInModal();
            }
          } else {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('getAccountByIdFromVoucherRequestEnd');
        },
        (err) => {
          // VueNotifications.error({ message: err });
          ctx.$store.commit('getAccountByIdFromVoucherRequestEnd');
          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          // ctx.$store.commit('toggleLoadingPortalUserAccounts');
        }
      );
  },

  async requestAccounts(searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'identity',
        with_childs: true
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/account`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  },
  async requestAccountsFilteredByLocationAndWithChilds(ctx, searchQuery) {
    const config = {
      params: {
        search: searchQuery,
        limit: 20,
        sort: 'identity',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
        with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
      }
    };
    const json = await Vue.axios.get(`${RESTAPI || ''}/api/portal/admin/account`, config);
    if (json.data.status === 'success') {
      return json.data.data;
    }
    return [];
  }
};
